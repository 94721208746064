import { Suspense, lazy, ComponentType } from 'react';
import { Box } from '@mui/material';
import Throbber from 'src/components/Throbber';

const SuspenseLoader = () => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100vh'
      }}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Throbber />
    </Box>
  );
};

const LazyLoader = (factory: () => Promise<{default: ComponentType<any>}>) => (props: any) => {
  const C = lazy(factory);
  return (
    <Suspense fallback={<SuspenseLoader />}>
      <C {...props} />
    </Suspense>
  );
};

export default LazyLoader;
