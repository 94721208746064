import { AvatarProps, Avatar as MUIAvatar, Typography } from '@mui/material';

import { User } from 'src/models';

export interface IAvatarProps {
  user: User;
  image: string;
}

const Avatar = ({ user, image }: IAvatarProps) => {
  const props: AvatarProps = image
    ? {
        alt: user.givenName,
        src: image
      }
    : {
        sx: {
          background: user.givenName?.toColor() ?? 'white'
        },
        children: user.initials
      };

  return (
    <MUIAvatar {...props} variant="square" />
  );
};

export default Avatar;
