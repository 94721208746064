import { styled, useTheme } from '@mui/material';
import { CSSProperties, SVGProps } from 'react';

import { Link } from 'react-router-dom';

const LogoWrapper = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: theme.sidebar.width
}));

interface LogoVariant {
  variant?: 'dark' | 'light';
}

const SvgComponent = (props: { fill: CSSProperties['color'] } & Partial<SVGProps<SVGSVGElement>>) => (
  <svg
    preserveAspectRatio="xMidYMid meet"
    data-bbox="39.39 60.56 325.55 125.92"
    viewBox="39.39 60.56 325.55 125.92"
    xmlns="http://www.w3.org/2000/svg"
    data-type="VillariLogoSVG"
    role="presentation"
    aria-hidden="true"
    aria-label=""
    {...props}
  >
    <g>
      <path
        d="M204.63 186.48c-2.92 0-3.91-6.18-6.17-62.91-.38-9.53-.79-19.93-1.26-29.39-4.49.23-8.34 1.08-11.76 2.32-6.9 17.31-19.48 34.62-44.73 34.62H39.39v-2.5H140.7c22.63 0 34.59-14.96 41.42-30.73-5.1 2.41-9.25 5.67-13.33 8.88-7.47 5.87-15.2 11.94-28.1 11.94H39.39v-2.5H140.7c12.03 0 19.08-5.54 26.55-11.41 4.85-3.81 9.8-7.7 16.24-10.25 3.29-8.49 5.22-16.92 6.63-23.12 1.76-7.69 2.48-10.87 4.66-10.87s3.34 3.65 4.76 31.06h.16c15.68 0 24.2 6.7 32.44 13.17 7.47 5.87 14.52 11.41 26.57 11.41h106.22v2.5H258.71c-12.91 0-20.64-6.07-28.11-11.95-7.91-6.21-16.08-12.64-30.89-12.64h-.03c.39 7.85.81 17.49 1.28 29.35.96 24.06 2.13 53.59 3.89 59.78.6-1.34 1.34-3.99 2.02-6.4 4.4-15.65 13.54-48.22 51.85-48.22h106.22v2.5H258.72c-36.41 0-45.21 31.34-49.44 46.39-1.73 6.16-2.52 8.96-4.65 8.96Zm-10-122.49c-.61 1.65-1.34 4.86-2.06 7.99-1.33 5.82-3.09 13.54-5.97 21.48 3.11-.94 6.56-1.58 10.48-1.78-.68-13.07-1.47-23.97-2.44-27.69Z"
        fill="#a0beea"
        data-color="1"
      />
      <path
        fill={props.fill}
        d="m55.71 98.91 23.46 49.2h5.54l21.81-49.2H98.9l-14.87 33.51-16.02-33.51z"
        data-color="2"
      />
      <path fill={props.fill} d="M113.03 148.11h11.87v-49.2h-11.87z" data-color="2" />
      <path fill={props.fill} d="M137.05 148.11h36.65v-5.04h-24.74V98.91h-11.91z" data-color="2" />
      <path fill={props.fill} d="M181.35 148.11H218v-5.04h-24.74V98.91h-11.91z" data-color="2" />
      <path
        fill={props.fill}
        d="M248.69 98.91h-5.12l-23.5 49.2h8.03l6.33-13.15h18.73l6.04 13.15h12.09l-22.61-49.2Zm-12.01 31.57 7.36-15.57 7.14 15.57z"
        data-color="2"
      />
      <path
        fill={props.fill}
        d="M304.37 124.56q2.505-.795 4.59-2.01c1.39-.81 2.58-1.74 3.56-2.79s1.74-2.2 2.29-3.45a9.9 9.9 0 0 0 .82-3.97q0-2.895-1.2-5.37c-.8-1.64-1.99-3.07-3.57-4.26-1.58-1.2-3.57-2.13-5.95-2.79s-5.17-1-8.36-1h-18.5v49.2h12.08v-22.24h2.14l16.51 22.24h13.13l-17.52-23.55Zm-2.12-8.2a8 8 0 0 1-2.16 2.81c-.92.76-2.01 1.35-3.27 1.76s-2.62.62-4.1.62h-2.61V103.7h4.9c1.16 0 2.24.22 3.23.65.99.44 1.84 1.05 2.54 1.83.7.79 1.25 1.72 1.65 2.81s.6 2.29.6 3.59c0 1.4-.26 2.66-.78 3.77Z"
        data-color="2"
      />
      <path fill={props.fill} d="M328.33 148.11h11.87v-49.2h-11.87z" data-color="2" />
    </g>
  </svg>
);

const Logo = ({ variant = 'dark' }: LogoVariant) => {
  const theme = useTheme();
  const fillColor = variant === 'dark' ? theme.palette.primary.main : theme.colors.white;

  return (
    <LogoWrapper to="/" sx={{ flexGrow: 0, display: { xs: 'none', sm: 'flex' } }}>
      <SvgComponent fill={fillColor} height={`calc(${theme.header.height} - 10px)`} />
    </LogoWrapper>
  );
};

export default Logo;
