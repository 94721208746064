import { Grid, Typography, Skeleton, Container, styled, Box } from '@mui/material';
import { FC } from 'react';

import { useDashboard } from 'src/contexts/DashboardContext';
import { useUser } from 'src/contexts/UserContext';
import { getTitle, getAssetName } from 'src/utils';

const PageTitleWrapper = styled(Box)(({ theme }) => ({
  position: 'sticky',
  top: theme.header.height,
  backgroundColor: theme.palette.background.default,
  backgroundClip: 'content-box',
  zIndex: theme.zIndex.appBar,
  paddingBottom: theme.spacing(0.5)
}));

const PageTitle = styled(Container)(({ theme }) => ({
  // background: theme.palette.background.default,
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(1),
  marginBottom: theme.spacing(1),
  boxShadow: theme.colors.shadows.primary
}));

interface IPageHeaderProps {
  title?: string;
  subtitle?: string;
}

const PageHeader: FC<IPageHeaderProps> = ({ title, subtitle, children }) => {
  const { item } = useDashboard();

  const _title = title || (item?.customer && item?.businessUnit && getTitle(item?.customer, item?.businessUnit));
  const _subtitle = subtitle || (item?.asset && getAssetName(item?.asset));

  return (
    <PageTitleWrapper>
      <PageTitle maxWidth={false}>
        <Grid alignItems="center">
          <Typography variant="h2" gutterBottom>
            {_title || <Skeleton variant="text" />}
          </Typography>
          {_subtitle && <Typography variant="subtitle1">{_subtitle}</Typography>}
        </Grid>
      </PageTitle>
      {children}
    </PageTitleWrapper>
  );
};

export default PageHeader;
