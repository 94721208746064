import { FlexInfo, MountedType } from './API';

export * from './API';
export * from './Request';
export * from './S3Image';
export * from './Tenant';
export * from './User';
export * from './ZoneState';

export const FLEXIBLE_LENGTH = 500; // mm
export const DEFAULT_INFO: FlexInfo = {
  __typename: 'FlexInfo',
  mountedType: MountedType.FLAT,
  startAngle: 0,
  isReversed: false,
  radius: null
};
