import { AppBar, Box, Toolbar, useTheme } from '@mui/material';
import MenuIcon from '@mui/icons-material/MenuTwoTone';

import { useApp } from 'src/contexts/AppContext';
import Logo from 'src/components/Logo';

import HeaderActionsBox from './HeaderActionsBox';
import HeaderUserBox from './HeaderUserBox';
import ActionButton from 'src/components/ActionButton';

function Header() {
  const { sidebar } = useApp();
  const theme = useTheme();

  return (
    <AppBar position="fixed" sx={{ zIndex: theme.zIndex.drawer + 1 }}>
      <Toolbar disableGutters sx={{ height: theme.header.height, justifyContent: 'space-between' }}>
        <Box sx={{ display: { sm: 'flex', md: 'none' }, minWidth: { sm: 125, xs: 0 } }}>
          <ActionButton icon={MenuIcon} onClick={sidebar.openHandler} />
        </Box>
        <Box sx={{ display: { xs: 'none', sm: 'flex' }, justifyContent: { sm: 'center', md: 'flex-start' } }}>
          <Logo variant="light" />
        </Box>
        <Box
          display="flex"
          sx={{ justifyContent: 'flex-end', minWidth: 125, gap: 1, alignItems: 'center' }}
        >
          <HeaderActionsBox />
          <HeaderUserBox />
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
