export enum Tenant {
  ADMIN = 'admin',
  STAFF = 'staff',
  ARCHIVIST = 'archivist',
  CUSTOMER = 'customer',
  END = 'end_customer',
  BASIC = 'basic'
}

export const tenantRank: { [key in Tenant]: number } = {
  [Tenant.ADMIN]: 1,
  [Tenant.STAFF]: 2,
  [Tenant.ARCHIVIST]: 3,
  [Tenant.CUSTOMER]: 4,
  [Tenant.END]: 5,
  [Tenant.BASIC]: 6
};

export const tenantComparator = (a: Tenant, b: Tenant): number => {
  const rankA = tenantRank[a];
  const rankB = tenantRank[b];

  if (rankA < rankB) return 1;
  if (rankA > rankB) return -1;
  return 0;
};

export const checkTenant = (t1: Tenant, t2: Tenant): boolean => tenantComparator(t1, t2) >= 0;

export const getUserTenant = (groups: Tenant[]): Tenant =>
  groups.reduce((t1, t2) => (checkTenant(t1, t2) ? t1 : t2), Tenant.BASIC);

export const getTenantList = (t: Tenant) =>
  Object.values(Tenant).filter((t2) => checkTenant(t, t2) && t2 !== Tenant.ARCHIVIST);
