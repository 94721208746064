import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { Box, Divider, Drawer, IconButton, styled, useTheme } from '@mui/material';

import { useApp } from 'src/contexts/AppContext';

import SidebarMenu from './SidebarMenu';

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 2),
  height: `calc(${theme.header.height} - 1px)`
}));

const DrawerBody = styled('div')(({ theme }) => ({
  height: `calc(100vh - (${theme.header.height} + ${theme.footer.height}))`,
  display: 'flex',
  flexDirection: 'column',
  width: theme.sidebar.width,
  minWdth: theme.sidebar.width,
  background: theme.sidebar.background,
  position: 'relative'
}));

const DrawerFooter = styled('div')(({ theme }) => ({
  height: `calc(${theme.footer.height} - 1px)`
}));

function Sidebar() {
  const { sidebar } = useApp();
  const theme = useTheme();

  return (
    <Drawer
      variant={sidebar.isPermanent ? 'permanent' : 'temporary'}
      open={sidebar.isOpen}
      onClose={sidebar.closeHandler}
      elevation={1}
      sx={{
        width: theme.sidebar.width,
        flexShrink: 0,
        overflow: 'auto',
        zIndex: sidebar.isPermanent ? theme.zIndex.drawer : theme.zIndex.drawer + 2,
        [`& .MuiDrawer-paper`]: {
          // Compensate 1px of Drawer's right border
          width: `calc(${theme.sidebar.width} + 1px)`,
          boxSizing: 'border-box'
        }
      }}
    >
      <DrawerHeader>
        <IconButton
          color="primary"
          onClick={sidebar.closeHandler}
          sx={{ display: { sm: 'flex', md: 'none' } }}
        >
          <KeyboardArrowLeftIcon />
        </IconButton>
      </DrawerHeader>
      <Divider
        sx={{
          mx: theme.spacing(2),
          background: theme.palette.primary.main
        }}
      />
      <DrawerBody>
        <SidebarMenu />
      </DrawerBody>
      <DrawerFooter />
    </Drawer>
  );
}

export default Sidebar;
