import { Box, CardContent, styled, useTheme } from '@mui/material';
import { CSSProperties } from 'react';

interface IRingProps {
  color: CSSProperties['color'];
  transform: CSSProperties['transform'];
  full: boolean;
}

const Ring = styled(Box, { shouldForwardProp: (prop) => prop !== 'full' })<IRingProps>(
  ({ color, transform, full, theme }) => ({
    '--ring-size': theme.spacing(full ? 6 : 3),
    '--ring-width': theme.spacing(full ? 0.5 : 0.25),
    position: 'absolute',
    width: 'var(--ring-size)',
    height: 'var(--ring-size)',
    transform,
    '&::after': {
      content: '""',
      position: 'absolute',
      width: 'var(--ring-size)',
      height: 'var(--ring-size)',
      border: '1px solid transparent',
      borderRadius: '50%',
      borderBottom: `var(--ring-width) solid ${full ? '#a0beea' : color}`,
      animation: 'atom 3s linear infinite'
    },
    '@keyframes atom': {
      '0%': {
        transform: 'skewX(45deg) rotate(0deg)'
      },
      '100%': {
        transform: 'skewX(45deg) rotate(360deg)'
      }
    }
  })
);

interface IThrobberProps {
  full?: boolean;
  card?: boolean;
}

const ThrobberWrapper = styled(Box, { shouldForwardProp: (prop) => prop !== 'full' })<IThrobberProps>(
  ({ theme, full }) =>
    full
      ? {
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          background: theme.palette.primary.main,
          fill: theme.palette.primary.contrastText,
          padding: theme.spacing(2)
        }
      : {
          fill: theme.palette.primary.main,
          padding: theme.spacing(2)
        }
);

const Throbber = ({ full, card }: IThrobberProps) => {
  const theme = useTheme();

  const throbber = (
    <ThrobberWrapper full={full}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        position="relative"
        height="auto"
        width="auto"
      >
        <Ring color={theme.colors.activityStatus.NO} transform="rotate(0deg)" full={full} />
        <Ring color={theme.colors.activityStatus.MODERATE} transform="rotate(120deg)" full={full} />
        <Ring color={theme.colors.activityStatus.SIGNIFICANT} transform="rotate(240deg)" full={full} />
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 691.4 669.5" height={full ? 20 : 10}>
          <path fill="inherit" d="M0 0l319.2 669.5h75.4L691.4 0H587.8L385.3 455.9 167.4 0z" />
        </svg>
      </Box>
    </ThrobberWrapper>
  );
  return card ? <CardContent>{throbber}</CardContent> : throbber;
};

export default Throbber;
