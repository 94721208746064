import { Auth } from 'aws-amplify';
import { useRef, useEffect } from 'react';

export const useIdleTimeout = (timeout = 15 * 60 * 1000) => {
  const timeoutRef = useRef(null);

  const resetTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      Auth.signOut();
    }, timeout);
  };

  useEffect(() => {
    const events = ['mousemove', 'keydown', 'click', 'scroll'];

    const handleActivity = (event) => {
      resetTimeout();
    };
    events.forEach((e) => {
      window.addEventListener(e, handleActivity);
    });

    return () => {
      events.forEach((e) => {
        window.removeEventListener(e, handleActivity);
      });
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, []);
};
