import { CrackImage, ImageType, LocationImage, OverviewImage, PipeImage } from 'src/models';

export type AvatarImage = {
  type: ImageType.AVATAR;
  user?: any;
  fileKey?: string;
};

export type FAQImage = {
  type: ImageType.FAQ;
  fileKey: string;
};

export type ImageVault = OverviewImage | LocationImage | CrackImage | PipeImage;
export type ImageOther = AvatarImage | FAQImage;
export type S3Image = ImageVault | ImageOther;

export type OverviewImageData = OverviewImage & {
  title: string;
  url: string;
  component: JSX.IntrinsicElements['img'];
};

export const isImageOther = (image?: S3Image): image is ImageOther => {
  if (!image) return false;
  return image.type === ImageType.AVATAR || image.type === ImageType.FAQ;
};
export const isImageVault = (image?: S3Image): image is ImageVault => {
  if (!image) return false;
  return (
    image.type === ImageType.OVERVIEW ||
    image.type === ImageType.LOCATION ||
    image.type === ImageType.CRACK ||
    image.type === ImageType.PIPE
  );
};
