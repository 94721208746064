import '@mui/lab/themeAugmentation';
import { alpha, createTheme, darken, lighten } from '@mui/material';
import { Font, StyleSheet } from '@react-pdf/renderer';

import pdfFontBold from '../fonts/OpenSans-Bold.ttf';
import pdfFontBoldItalic from '../fonts/OpenSans-BoldItalic.ttf';
import pdfFontExtraBold from '../fonts/OpenSans-ExtraBold.ttf';
import pdfFontExtraBoldItalic from '../fonts/OpenSans-ExtraBoldItalic.ttf';
import pdfFontItalic from '../fonts/OpenSans-Italic.ttf';
import pdfFontLight from '../fonts/OpenSans-Light.ttf';
import pdfFontLightItalic from '../fonts/OpenSans-LightItalic.ttf';
import pdfFontRegular from '../fonts/OpenSans-Regular.ttf';
import pdfFontSemiBold from '../fonts/OpenSans-SemiBold.ttf';
import pdfFontSemiBoldItalic from '../fonts/OpenSans-SemiBoldItalic.ttf';

const colors = {
  primary: {
    50: '#e6ebf0',
    100: '#b0c0d0',
    200: '#8aa1b9',
    300: '#547698',
    400: '#335c85',
    500: '#003366',
    600: '#002e5d',
    700: '#002448',
    800: '#001c38',
    900: '#00152b'
  },
  success: {
    50: '#ebfaeb',
    100: '#bff0bf',
    200: '#a1e8a1',
    300: '#76de76',
    400: '#5bd75b',
    500: '#32cd32',
    600: '#2ebb2e',
    700: '#249224',
    800: '#1c711c',
    900: '#155615'
  },
  warning: {
    50: '#fffbe6',
    100: '#fff3b0',
    200: '#ffed8a',
    300: '#ffe454',
    400: '#ffdf33',
    500: '#ffd700',
    600: '#e8c400',
    700: '#b59900',
    800: '#8c7600',
    900: '#6b5a00'
  },
  error: {
    50: '#ffe6e6',
    100: '#ffb0b0',
    200: '#ff8a8a',
    300: '#ff5454',
    400: '#ff3333',
    500: '#ff0000',
    600: '#e80000',
    700: '#b50000',
    800: '#8c0000',
    900: '#6b0000'
  },
  info: {
    50: '#ecf0fc',
    100: '#c4d1f6',
    200: '#a8baf1',
    300: '#809beb',
    400: '#6787e7',
    500: '#4169e1',
    600: '#3b60cd',
    700: '#2e4ba0',
    800: '#243a7c',
    900: '#1b2c5f'
  },
  hint: {
    50: '#f4effc',
    100: '#ddccf7',
    200: '#ccb4f3',
    300: '#b592ee',
    400: '#a77dea',
    500: '#915ce5',
    600: '#8454d0',
    700: '#6741a3',
    800: '#50337e',
    900: '#3d2760'
  },
  grey: {
    50: '#f3f4f4',
    100: '#d9dbde',
    200: '#c7cacd',
    300: '#aeb2b7',
    400: '#9ea3a9',
    500: '#868c93',
    600: '#7a7f86',
    700: '#5f6368',
    800: '#4b4e53',
    900: '#383b3e'
  },
  black: '#231f20',
  white: '#ffffff',
  accent: '#85ffc9',
  hover: '#d9e1ed',
  selected: '#a0beea',
  steel: '#4b4e53',
  background: '#f5f8ff',
  shadows: {
    zero: 'none',
    primary: 'none',
    success: 'none',
    warning: 'none',
    error: 'none',
    info: 'none',
    card: 'none'
  }
};

const borders = {
  primary: `1px solid ${colors.primary[500]}`,
  white: `1px solid ${colors.white}`,
  accent: `1px solid ${colors.accent}`,
  disabled: `1px solid ${colors.grey[500]}`
};

export const LightTheme = createTheme({
  colors: {
    primary: colors.primary,
    success: colors.success,
    warning: colors.warning,
    error: colors.error,
    info: colors.info,
    grey: colors.grey,
    black: colors.black,
    white: colors.white,
    activityStatus: {
      NO: colors.success[500],
      MODERATE: colors.warning[500],
      SIGNIFICANT: colors.error[500],
      OFFLINE: colors.grey[500],
      PENDING: colors.info[500],
      ARCHIVED: colors.hint[500]
    },
    shadows: {
      primary: colors.shadows.primary,
      success: colors.shadows.success,
      warning: colors.shadows.warning,
      error: colors.shadows.error,
      info: colors.shadows.info,
      grey: colors.shadows.card
    }
  },
  borders: {
    borderRadiusSm: '0rem',
    borderRadius: '0rem',
    borderRadiusLg: '0rem',
    borderRadiusXl: '0rem',
    ...borders
  },
  sidebar: {
    background: colors.white,
    boxShadow: '0px 0px 0px 0px',
    width: '18rem'
  },
  header: {
    height: '3rem',
    background: colors.primary[500],
    boxShadow: '0px 0px 0px 0px',
    textColor: colors.white
  },
  footer: {
    height: '2rem',
    background: colors.primary[500],
    boxShadow: '0px 0px 0px 0px',
    textColor: colors.white
  },
  spacing: 9,
  palette: {
    mode: 'light',
    common: {
      black: colors.black,
      white: colors.white
    },
    primary: {
      light: colors.primary[100],
      main: colors.primary[500],
      dark: colors.primary[800],
      contrastText: colors.white
    },
    secondary: {
      light: lighten(colors.accent, 0.2),
      main: colors.accent,
      dark: darken(colors.accent, 0.2),
      contrastText: colors.black
    },
    error: {
      light: colors.error[50],
      main: colors.error[500],
      dark: colors.error[900],
      contrastText: colors.white
    },
    success: {
      light: colors.success[50],
      main: colors.success[500],
      dark: colors.success[900],
      contrastText: colors.white
    },
    info: {
      light: colors.info[50],
      main: colors.info[500],
      dark: colors.info[900],
      contrastText: colors.white
    },
    warning: {
      light: colors.warning[50],
      main: colors.warning[500],
      dark: colors.warning[900],
      contrastText: colors.white
    },
    text: {
      primary: colors.black,
      secondary: colors.primary[500],
      disabled: colors.grey[500]
    },
    background: {
      paper: colors.white,
      default: colors.background
    },
    action: {
      active: colors.black,
      hover: colors.hover,
      hoverOpacity: 0.1,
      selected: colors.selected,
      selectedOpacity: 0.1,
      disabled: colors.grey[500],
      disabledOpacity: 0.1,
      disabledBackground: colors.grey[500],
      focus: colors.accent,
      focusOpacity: 0.1,
      activatedOpacity: 0.12
    },
    tonalOffset: 0.5
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1840
    }
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: colors.shadows.zero
        }
      }
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: alpha(colors.black, 0.2),
          backdropFilter: 'blur(2px)',

          '&.MuiBackdrop-invisible': {
            backgroundColor: 'transparent',
            backdropFilter: 'blur(2px)'
          }
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          marginLeft: 8,
          marginRight: 8,
          fontWeight: 'bold'
        }
      }
    },
    MuiCssBaseline: {
      styleOverrides: {
        'html, body': {
          width: '100%',
          height: '100%'
        },
        body: {
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100%',
          width: '100%',
          flex: 1
        },
        '#root': {
          width: '100%',
          height: '100%',
          display: 'flex',
          flex: 1,
          flexDirection: 'column'
        },
        html: {
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100%',
          width: '100%',
          MozOsxFontSmoothing: 'grayscale',
          WebkitFontSmoothing: 'antialiased'
        },
        '.child-popover .MuiPaper-root .MuiList-root': {
          flexDirection: 'column'
        },
        ':root': {
          '--swiper-theme-color': colors.primary[500]
        },
        code: {
          background: colors.info[200],
          color: colors.info[900],
          borderRadius: 0,
          padding: 4
        },
        '@keyframes ripple': {
          '0%': {
            transform: 'scale(.8)',
            opacity: 1
          },
          '100%': {
            transform: 'scale(2.8)',
            opacity: 0
          }
        },
        '@keyframes float': {
          '0%': {
            transform: 'translate(0%, 0%)'
          },
          '100%': {
            transform: 'translate(3%, 3%)'
          }
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        iconOutlined: {
          color: alpha(colors.black, 0.5)
        },
        icon: {
          top: 'calc(50% - 14px)'
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& .MuiInputAdornment-positionEnd.MuiInputAdornment-outlined': {
            paddingRight: 6
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: alpha(colors.black, 0.5)
          },
          '&.Mui-focused:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: colors.primary[500]
          }
        }
      }
    },
    MuiListSubheader: {
      styleOverrides: {
        colorPrimary: {
          fontWeight: 'bold',
          lineHeight: '40px',
          fontSize: 13,
          background: alpha(colors.black, 0.02),
          color: alpha(colors.black, 0.7)
        }
      }
    },
    MuiTreeItem: {
      styleOverrides: {
        root: {
          marginTop: '0.25rem',
          color: colors.primary[500],
          '& .MuiTreeItem-content': {
            color: colors.primary[500],
            borderRadius: '0rem',
            paddingTop: '0.25rem',
            paddingBottom: '0.25rem',
            '&:hover': {
              background: colors.hover
            },
            '&.Mui-focused, &.Mui-selection, &.Mui-selection.Mui-focused': {
              background: colors.hover,
              color: 'inherit'
            },
            '&.Mui-selected': {
              background: colors.white,
              color: 'inherit'
            },
            '& .MuiTreeItem-label': {
              color: 'inherit'
            }
          },
          '& .MuiTreeItem-group': {
            marginLeft: 0,
            '& .MuiTreeItem-content': {
              paddingLeft: '2.5rem'
            }
          }
        }
      }
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          variant: 'h4',
          color: null
        },
        subheaderTypographyProps: {
          variant: 'subtitle2',
          color: null
        }
      },
      styleOverrides: {
        root: {
          position: 'relative',
          overflow: 'hidden',
          '&::before': {
            content: '""',
            display: 'block',
            height: '1px',
            width: '100%',
            position: 'absolute',
            bottom: '0',
            backgroundColor: colors.grey[100],
            margin: '0',
            left: '0'
          }
        },
        action: {
          marginTop: 'auto',
          marginBottom: 'auto'
        }
      }
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          borderRadius: '0px'
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        colorSecondary: {
          background: alpha(colors.black, 0.02),
          color: colors.black,

          '&:hover': {
            background: alpha(colors.black, 0.1)
          }
        },
        deleteIcon: {
          color: colors.error[300],

          '&:hover': {
            color: colors.error[500]
          }
        }
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          '&.Mui-expanded': {
            margin: 0
          },
          '&::before': {
            display: 'none'
          },
          '& .Mui-expanded .MuiTypography-root': {
            fontWeight: 700
          },
          '&.Mui-disabled': {
            background: 'none'
          }
        }
      }
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            opacity: 1,
            '& .MuiAccordionSummary-expandIconWrapper': {
              opacity: 0.3
            }
          }
        }
      }
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          fontSize: 14,
          fontWeight: 'bold'
        },
        colorDefault: {
          background: alpha(colors.black, 0.3),
          color: colors.white
        }
      }
    },
    MuiAvatarGroup: {
      styleOverrides: {
        root: {
          alignItems: 'center'
        },
        avatar: {
          background: alpha(colors.black, 0.1),
          fontSize: 13,
          color: alpha(colors.black, 0.7),
          fontWeight: 'bold',

          '&:first-of-type': {
            border: 0,
            background: 'transparent'
          }
        }
      }
    },
    MuiListItemAvatar: {
      styleOverrides: {
        alignItemsFlexStart: {
          marginTop: 0
        }
      }
    },
    MuiPaginationItem: {
      styleOverrides: {
        page: {
          fontSize: 13,
          fontWeight: 'bold',
          transition: 'all .2s'
        },
        textPrimary: {
          '&.Mui-selected': {
            boxShadow: colors.shadows.primary
          },
          '&.MuiButtonBase-root:hover': {
            background: alpha(colors.black, 0.02)
          },
          '&.Mui-selected.MuiButtonBase-root:hover': {
            background: colors.primary[500]
          }
        }
      }
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true
      },
      variants: [
        {
          props: { variant: 'accent' },
          style: {
            background: colors.accent,
            color: colors.primary[500],
            border: `1px solid ${colors.accent}`,
            '&:hover': {
              background: colors.primary[500],
              color: colors.accent
            }
          }
        },
        {
          props: { variant: 'accent-outlined' },
          style: {
            background: colors.primary[500],
            color: colors.white,
            border: `1px solid ${colors.white}`,
            '&:hover': {
              background: colors.white,
              color: colors.primary[500]
            }
          }
        },
        {
          props: { variant: 'accent-text' },
          style: {
            // background: colors.primary[500],
            color: colors.white,
            '&:hover': {
              color: colors.accent
            }
          }
        }
      ],
      styleOverrides: {
        root: {
          fontWeight: 'bold',
          textTransform: 'none',
          paddingLeft: 16,
          paddingRight: 16,

          '.MuiSvgIcon-root': {
            transition: 'all .2s'
          }
        },
        endIcon: {
          marginRight: -8
        },
        contained: {
          background: colors.primary[500],
          color: colors.white,
          border: `1px solid ${colors.primary[500]}`,
          boxShadow: colors.shadows.zero,
          '&:hover': {
            background: colors.white,
            color: colors.primary[500],
            boxShadow: colors.shadows.zero
          }
        },
        outlined: {
          color: colors.primary[500],
          border: `1px solid ${colors.primary[500]}`,
          boxShadow: colors.shadows.zero
        },
        containedSecondary: {
          backgroundColor: colors.white,
          color: colors.white,
          border: `1px solid ${alpha(colors.black, 0.3)}`
        },
        outlinedSecondary: {
          backgroundColor: colors.white,

          '&:hover, &.MuiSelected': {
            backgroundColor: alpha(colors.black, 0.02),
            color: colors.black
          }
        },
        sizeSmall: {
          padding: '6px 16px',
          lineHeight: 1.5
        },
        sizeMedium: {
          padding: '8px 20px'
        },
        sizeLarge: {
          padding: '11px 24px'
        },
        textSizeSmall: {
          padding: '7px 12px'
        },
        textSizeMedium: {
          padding: '9px 16px'
        },
        textSizeLarge: {
          padding: '12px 16px'
        }
      }
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: false
      },
      styleOverrides: {
        root: {
          borderRadius: 0
        }
      }
    },
    MuiToggleButton: {
      defaultProps: {
        disableRipple: true
      },
      styleOverrides: {
        root: {
          background: colors.white,
          color: colors.primary[500],
          border: borders.primary,
          fontWeight: 700,
          transition: 'all .2s',
          '&:hover': {
            background: colors.hover
          },
          '&.Mui-selected': {
            background: colors.primary[500],
            color: colors.white
          },
          '&.Mui-selected:hover': {
            background: colors.primary[500]
          }
        }
      }
    },
    MuiIconButton: {
      variants: [
        {
          props: { color: 'accent' },
          style: {
            color: colors.white,
            '&:hover': {
              color: colors.accent,
              background: 'inherit'
            }
          }
        }
      ],
      styleOverrides: {
        root: {
          borderRadius: 0,
          padding: 8,

          '& .MuiTouchRipple-root': {
            borderRadius: 0
          }
        },

        colorPrimary: {},

        sizeSmall: {
          padding: 4
        }
      }
    },
    MuiSvgIcon: {
      styleOverrides: {
        colorSecondary: {
          color: colors.primary[500],
          '& path:nth-of-type(1)': {
            opacity: 1,
            color: colors.accent
          }
        }
      }
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          color: 'inherit',
          margin: 0
        }
      }
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          color: 'inherit',
          '& .MuiTouchRipple-root': {
            opacity: 0.3
          }
        }
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: 'inherit',
          '& .MuiTouchRipple-root': {
            opacity: 0.3
          }
        }
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          background: colors.grey[100],
          border: 0,
          height: 1
        },
        vertical: {
          height: 'auto',
          width: 1,

          '&.MuiDivider-flexItem.MuiDivider-fullWidth': {
            height: 'auto'
          },
          '&.MuiDivider-absolute.MuiDivider-fullWidth': {
            height: '100%'
          }
        },
        withChildren: {
          '&:before, &:after': {
            border: 0
          }
        },
        wrapper: {
          background: colors.white,
          fontWeight: 'bold',
          height: 24,
          lineHeight: '24px',
          marginTop: -12,
          color: 'inherit',
          textTransform: 'uppercase'
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          padding: 0
        },
        elevation0: {
          boxShadow: 'none'
        },
        elevation: {
          boxShadow: colors.shadows.card
        },
        elevation2: {
          boxShadow: colors.shadows.card
        },
        elevation24: {
          boxShadow: colors.shadows.card
        },
        outlined: {
          boxShadow: colors.shadows.card
        }
      }
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover'
      }
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          height: 6
        }
      }
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          '& .MuiSlider-valueLabelCircle, .MuiSlider-valueLabelLabel': {
            transform: 'none'
          },
          '& .MuiSlider-valueLabel': {
            borderRadius: 0,
            background: colors.black,
            color: colors.white
          }
        }
      }
    },
    MuiList: {
      styleOverrides: {
        root: {
          padding: 0,

          '& .MuiListItem-button': {
            transition: 'all .2s',

            '& > .MuiSvgIcon-root': {
              minWidth: 34
            },

            '& .MuiTouchRipple-root': {
              opacity: 0.2
            }
          },
          '& .MuiListItem-root.MuiButtonBase-root.Mui-selected': {
            backgroundColor: alpha(colors.primary[200], 0.4)
          },
          '& .MuiMenuItem-root.MuiButtonBase-root:active': {
            backgroundColor: alpha(colors.primary[200], 0.4)
          },
          '& .MuiMenuItem-root.MuiButtonBase-root .MuiTouchRipple-root': {
            opacity: 0.2
          }
        },
        padding: {
          padding: '12px',

          '& .MuiListItem-button': {
            borderRadius: 0,
            margin: '1px 0'
          }
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          height: 38,
          minHeight: 38,
          overflow: 'visible'
        },
        indicator: {
          height: 38,
          minHeight: 38,
          borderRadius: 0,
          border: `1px solid ${colors.primary[900]}`
        },
        scrollableX: {
          overflow: 'visible !important'
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          padding: 0,
          height: 38,
          minHeight: 38,
          borderRadius: 0,
          border: '1px solid ' + colors.primary[500],
          transition: 'color .2s',
          textTransform: 'capitalize',
          color: colors.primary[500],
          fontWeight: 700,
          fontSize: '0.875rem',

          '&.MuiButtonBase-root': {
            minWidth: 'auto',
            paddingLeft: 20,
            paddingRight: 20
          },
          '&.MuiButtonBase-root.inbetween': {
            marginLeft: 2,
            marginRight: 2
          },
          '&.MuiButtonBase-root.first': {
            marginRight: 2
          },
          '&.MuiButtonBase-root.last': {
            marginLeft: 2
          },
          '&.Mui-selected, &.Mui-selected:hover': {
            background: 'inherit',
            color: colors.white,
            zIndex: 5
          },
          '&:hover': {
            background: colors.hover
          }
        }
      }
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          padding: 12
        },
        list: {
          padding: 12,

          '& .MuiMenuItem-root.MuiButtonBase-root': {
            fontSize: 14,
            marginTop: 1,
            marginBottom: 1,
            transition: 'all .2s',
            color: alpha(colors.black, 0.7),

            '& .MuiTouchRipple-root': {
              opacity: 0.2
            },

            '&:hover, &:active, &.active, &.Mui-selected': {
              color: colors.black,
              background: alpha(colors.primary[200], 0.4)
            }
          }
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          background: 'transparent',
          transition: 'all .2s',

          '&:hover, &:active, &.active, &.Mui-selected': {
            color: colors.black,
            background: alpha(colors.primary[200], 0.4)
          },
          '&.Mui-selected:hover': {
            background: alpha(colors.primary[200], 0.4)
          }
        }
      }
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '&.MuiButtonBase-root': {
            color: colors.white,

            '&:hover, &:active, &.active, &.Mui-selected': {
              color: colors.black,
              background: colors.primary[50]
            }
          }
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        tag: {
          margin: 1
        },
        root: {
          '.MuiAutocomplete-inputRoot.MuiOutlinedInput-root .MuiAutocomplete-endAdornment': {
            right: 14
          }
        },
        clearIndicator: {
          background: colors.error[200],
          color: colors.error[500],
          marginRight: 8,

          '&:hover': {
            background: colors.error[200],
            color: colors.error[800]
          }
        },
        popupIndicator: {
          color: alpha(colors.black, 0.5),

          '&:hover': {
            background: colors.primary[200],
            color: colors.primary[500]
          }
        }
      }
    },
    MuiTablePagination: {
      styleOverrides: {
        toolbar: {
          '& .MuiIconButton-root': {
            padding: 8
          }
        },
        select: {
          '&:focus': {
            backgroundColor: 'transparent'
          }
        }
      }
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: '0 !important',
          padding: '0 !important'
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        head: {
          background: alpha(colors.black, 0.02)
        },
        root: {
          transition: 'background-color .2s',

          '&.MuiTableRow-hover:hover': {
            backgroundColor: alpha(colors.black, 0.02)
          }
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottomColor: alpha(colors.black, 0.1),
          fontSize: 14
        },
        head: {
          textTransform: 'uppercase',
          fontSize: 13,
          fontWeight: 'bold',
          color: alpha(colors.black, 0.7)
        }
      }
    },
    MuiAlert: {
      styleOverrides: {
        message: {
          lineHeight: 1.5,
          fontSize: 14
        },
        standardInfo: {
          color: colors.info[500]
        },
        action: {
          color: alpha(colors.black, 0.7)
        }
      }
    },
    MuiTimelineDot: {
      styleOverrides: {
        root: {
          margin: 0,
          zIndex: 5,
          position: 'absolute',
          top: '50%',
          marginTop: -6,
          left: -6
        },
        outlined: {
          backgroundColor: colors.white,
          boxShadow: '0px' // `0 0 0 6px ${colors.white}`
        },
        outlinedPrimary: {
          backgroundColor: colors.white,
          boxShadow: '0px' //`0 0 0 6px ${colors.white}`
        }
      }
    },
    MuiTimelineConnector: {
      styleOverrides: {
        root: {
          position: 'absolute',
          height: '100%',
          top: 0,
          borderRadius: 0,
          backgroundColor: alpha(colors.black, 0.1)
        }
      }
    },
    MuiTimelineItem: {
      styleOverrides: {
        root: {
          minHeight: 0,
          padding: '8px 0',

          '&:before': {
            display: 'none'
          }
        },
        missingOppositeContent: {
          '&:before': {
            display: 'none'
          }
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: alpha(colors.primary[900], 0.7),
          padding: '8px 16px',
          fontSize: 12
        },
        arrow: {
          color: alpha(colors.primary[900], 0.7)
        }
      }
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          height: 33,
          overflow: 'visible',

          '& .MuiButtonBase-root': {
            position: 'absolute',
            padding: 6,
            transition:
              'left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
          },
          '& .MuiIconButton-root': {
            borderRadius: 0
          },
          '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
            opacity: 0.3
          }
        },
        thumb: {
          border: `1px solid ${colors.grey[200]}`,
          boxShadow: '0px' //`0px 9px 14px ${alpha(colors.black, 0.1)}, 0px 2px 2px ${alpha(colors.black, 0.1)}`
        },
        track: {
          backgroundColor: alpha(colors.black, 0.02),
          border: `1px solid ${alpha(colors.black, 0.1)}`,
          boxShadow: '0px', //`inset 0px 1px 1px ${alpha(colors.black, 0.1)}`,
          opacity: 1
        },
        colorPrimary: {
          '& .MuiSwitch-thumb': {
            backgroundColor: colors.white
          },

          '&.Mui-checked .MuiSwitch-thumb': {
            backgroundColor: colors.primary[500]
          }
        }
      }
    },
    MuiStepper: {
      styleOverrides: {
        root: {
          paddingTop: 20,
          paddingBottom: 20,
          background: alpha(colors.black, 0.02)
        }
      }
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          '&.MuiStepIcon-completed': {
            color: colors.success[500]
          }
        }
      }
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: 'h1',
          h2: 'h2',
          h3: 'div',
          h4: 'div',
          h5: 'div',
          h6: 'div',
          subtitle1: 'div',
          subtitle2: 'div',
          body1: 'div',
          body2: 'div'
        }
      },
      styleOverrides: {
        gutterBottom: {
          marginBottom: 0
        },
        paragraph: {
          fontSize: 17,
          lineHeight: 1.7
        }
      }
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          background: colors.hover
        }
      }
    }
  },
  shape: {
    borderRadius: 0
  },
  typography: {
    fontFamily: 'Open Sans',
    h1: {
      fontWeight: 700,
      fontSize: 30,
      color: colors.black
    },
    h2: {
      fontWeight: 700,
      fontSize: 24,
      color: colors.black
    },
    h3: {
      fontWeight: 700,
      fontSize: 20,
      color: colors.black
    },
    h4: {
      fontWeight: 700,
      fontSize: 18,
      color: colors.black
    },
    h5: {
      fontWeight: 700,
      fontSize: 16,
      color: colors.black
    },
    h6: {
      fontWeight: 700,
      fontSize: 14,
      color: colors.black
    },
    body1: {
      fontSize: 14,
      color: colors.black
    },
    body2: {
      fontSize: 12,
      color: colors.steel
    },
    button: {
      fontWeight: 500,
      fontSize: 12,
      color: colors.primary[500]
    },
    caption: {
      fontSize: 10,
      textTransform: 'uppercase',
      color: colors.primary[400]
    },
    subtitle1: {
      fontSize: 16,
      color: colors.steel
    },
    subtitle2: {
      fontWeight: 400,
      fontSize: 14,
      color: colors.steel
    },
    overline: {
      fontSize: 10,
      fontWeight: 300,
      textTransform: 'uppercase',
      color: colors.primary[500]
    }
  }
});

export const echartsTheme = {
  color: [
    colors.primary[300],
    colors.primary[400],
    colors.primary[500],
    colors.primary[600],
    colors.primary[700]
  ],
  backgroundColor: 'rgba(0, 0, 0, 0)',
  textStyle: {
    fontFamily: 'Open Sans',
    fontSize: 20
  },
  title: {
    textStyle: {
      color: colors.primary[500]
    },
    subtextStyle: {
      color: colors.black
    }
  },
  line: {
    itemStyle: {
      borderWidth: 1
    },
    lineStyle: {
      width: 2
    },
    symbolSize: 4,
    symbol: 'none',
    smooth: false
  },
  radar: {
    itemStyle: {
      borderWidth: 1
    },
    lineStyle: {
      width: 2
    },
    symbolSize: 4,
    symbol: 'emptyCircle',
    smooth: false
  },
  bar: {
    itemStyle: {
      barBorderWidth: 0,
      barBorderColor: '#ccc'
    }
  },
  pie: {
    itemStyle: {
      borderWidth: 0,
      borderColor: '#ccc'
    }
  },
  scatter: {
    itemStyle: {
      borderWidth: 0,
      borderColor: '#ccc'
    }
  },
  boxplot: {
    itemStyle: {
      color: colors.primary[300],
      lineStyle: {
        width: 1,
        color: '#ccc'
      },
      borderWidth: 1,
      borderColor: '#444'
    }
  },
  parallel: {
    itemStyle: {
      borderWidth: 0,
      borderColor: '#ccc'
    }
  },
  sankey: {
    itemStyle: {
      borderWidth: 0,
      borderColor: '#ccc'
    }
  },
  funnel: {
    itemStyle: {
      borderWidth: 0,
      borderColor: '#ccc'
    }
  },
  gauge: {
    itemStyle: {
      borderWidth: 0,
      borderColor: '#ccc'
    }
  },
  candlestick: {
    itemStyle: {
      color: '#eb5454',
      color0: '#47b262',
      borderColor: '#eb5454',
      borderColor0: '#47b262',
      borderWidth: 1
    }
  },
  graph: {
    itemStyle: {
      borderWidth: 0,
      borderColor: '#ccc'
    },
    lineStyle: {
      width: 1,
      color: '#aaa'
    },
    symbolSize: 4,
    symbol: 'emptyCircle',
    smooth: false,
    color: [
      '#5470c6',
      '#91cc75',
      '#fac858',
      '#ee6666',
      '#73c0de',
      '#3ba272',
      '#fc8452',
      '#9a60b4',
      '#ea7ccc'
    ],
    label: {
      color: '#eee'
    }
  },
  map: {
    itemStyle: {
      areaColor: '#eee',
      borderColor: '#444',
      borderWidth: 0.5
    },
    label: {
      color: '#000'
    },
    emphasis: {
      itemStyle: {
        areaColor: 'rgba(255,215,0,0.8)',
        borderColor: '#444',
        borderWidth: 1
      },
      label: {
        color: 'rgb(100,0,0)'
      }
    }
  },
  geo: {
    itemStyle: {
      areaColor: '#eee',
      borderColor: '#444',
      borderWidth: 0.5
    },
    label: {
      color: '#000'
    },
    emphasis: {
      itemStyle: {
        areaColor: 'rgba(255,215,0,0.8)',
        borderColor: '#444',
        borderWidth: 1
      },
      label: {
        color: 'rgb(100,0,0)'
      }
    }
  },
  categoryAxis: {
    axisLine: {
      show: true,
      lineStyle: {
        color: colors.black
      }
    },
    axisTick: {
      show: true,
      lineStyle: {
        color: colors.black
      }
    },
    axisLabel: {
      show: true,
      color: colors.grey[500]
    },
    splitLine: {
      show: false,
      lineStyle: {
        color: ['#E0E6F1']
      }
    },
    splitArea: {
      show: false,
      areaStyle: {
        color: ['rgba(250,250,250,0.2)', 'rgba(210,219,238,0.2)']
      }
    }
  },
  valueAxis: {
    axisLine: {
      show: false,
      lineStyle: {
        color: colors.black
      }
    },
    axisTick: {
      show: false,
      lineStyle: {
        color: colors.black
      }
    },
    axisLabel: {
      show: true,
      color: colors.grey[500]
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: ['#E0E6F1']
      }
    },
    splitArea: {
      show: false,
      areaStyle: {
        color: ['rgba(250,250,250,0.2)', 'rgba(210,219,238,0.2)']
      }
    }
  },
  logAxis: {
    axisLine: {
      show: false,
      lineStyle: {
        color: colors.black
      }
    },
    axisTick: {
      show: false,
      lineStyle: {
        color: colors.black
      }
    },
    axisLabel: {
      show: true,
      color: colors.black
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: ['#E0E6F1']
      }
    },
    splitArea: {
      show: false,
      areaStyle: {
        color: ['rgba(250,250,250,0.2)', 'rgba(210,219,238,0.2)']
      }
    }
  },
  timeAxis: {
    axisLine: {
      show: true,
      lineStyle: {
        color: colors.black
      }
    },
    axisTick: {
      show: true,
      lineStyle: {
        color: colors.black
      }
    },
    axisLabel: {
      show: true,
      formatter: {
        year: '{yearStyle|{yyyy}}',
        month: '{monthStyle|{MMM}}',
        day: '{dayStyle|{d}}',
        hour: '{HH}:{mm}',
        minute: '{HH}:{mm}',
        second: '{HH}:{mm}:{ss}',
        millisecond: '{hh}:{mm}:{ss} {SSS}',
        none: '{yyyy}-{MM}-{dd} {hh}:{mm}:{ss} {SSS}'
      },
      rich: {
        yearStyle: {
          color: colors.primary[500],
          fontWeight: 'bold',
          fontSize: '16px'
        },
        monthStyle: {
          color: colors.black,
          fontWeight: 'bold',
          fontSize: '16px'
        },
        dayStyle: {
          color: colors.black,
          fontSize: '16px'
        }
      }
    },
    splitLine: {
      show: false,
      lineStyle: {
        color: ['#E0E6F1']
      }
    },
    splitArea: {
      show: false,
      areaStyle: {
        color: ['rgba(250,250,250,0.2)', 'rgba(210,219,238,0.2)']
      }
    }
  },
  toolbox: {
    iconStyle: {
      borderColor: colors.black
    },
    emphasis: {
      iconStyle: {
        borderColor: colors.primary[500]
      }
    }
  },
  legend: {
    textStyle: {
      color: '#333',
      fontSize: 12
    }
  },
  tooltip: {
    textStyle: {
      color: colors.black,
      fontFamily: 'inherit'
    },
    axisPointer: {
      lineStyle: {
        color: '#ccc',
        width: 1
      },
      crossStyle: {
        color: '#ccc',
        width: 1
      }
    }
  },
  timeline: {
    lineStyle: {
      color: '#DAE1F5',
      width: 2
    },
    itemStyle: {
      color: '#A4B1D7',
      borderWidth: 1
    },
    controlStyle: {
      color: '#A4B1D7',
      borderColor: '#A4B1D7',
      borderWidth: 1
    },
    checkpointStyle: {
      color: '#316bf3',
      borderColor: 'fff'
    },
    label: {
      color: '#A4B1D7'
    },
    emphasis: {
      itemStyle: {
        color: '#FFF'
      },
      controlStyle: {
        color: '#A4B1D7',
        borderColor: '#A4B1D7',
        borderWidth: 1
      },
      label: {
        color: '#A4B1D7'
      }
    }
  },
  visualMap: {
    color: ['#bf444c', '#d88273', '#f6efa6']
  },
  dataZoom: {
    textStyle: {
      fontSize: 12
    },
    borderColor: colors.black,
    fillerColor: alpha(colors.primary[300], 0.2),
    dataBackground: {
      lineStyle: {
        color: colors.grey[300]
      },
      areaStyle: {
        color: colors.grey[300]
      }
    },
    selectedDataBackground: {
      lineStyle: {
        color: colors.primary[500]
      },
      areaStyle: {
        color: colors.primary[500]
      }
    },
    brushStyle: {
      color: alpha(colors.primary[300], 0.2)
    },
    handleStyle: {
      color: colors.white,
      borderColor: colors.black
    },
    moveHandleStyle: {
      color: colors.primary[100],
      borderColor: colors.black
    },
    emphasis: {
      handleStyle: {
        color: colors.primary[200],
        borderColor: colors.primary[500]
      },
      moveHandleStyle: {
        color: colors.primary[500]
      }
    }
  },
  markPoint: {
    label: {
      color: '#eee'
    },
    emphasis: {
      label: {
        color: '#eee'
      }
    }
  }
};

export const pdfTheme = StyleSheet.create({
  body: {
    paddingVertical: 20,
    paddingHorizontal: 30
  },
  header: {
    fontFamily: 'open-sans',
    fontSize: 10,
    color: 'grey',
    marginVertical: 'auto'
  },
  headerLogo: {
    height: 50,
    width: 'auto',
    left: -10
  },
  footer: {
    fontFamily: 'open-sans',
    fontSize: 10,
    bottom: -20,
    color: 'grey'
  },
  disclaimer: {
    fontFamily: 'open-sans',
    fontSize: 9,
    fontStyle: 'italic',
    bottom: -25,
    color: 'grey'
  },
  row: {
    flexDirection: 'row'
  },
  column: {
    flexDirection: 'column'
  },
  p: {
    fontFamily: 'open-sans',
    fontSize: 12,
    color: '#383838',
    lineHeight: 1.3,
    letterSpacing: 0.3,
    paddingBottom: 10
  },
  title: {
    width: '100%',
    textAlign: 'center',
    justifyContent: 'center',
    backgroundColor: '#e6eef1'
  },
  h1: {
    fontFamily: 'open-sans',
    fontWeight: 700,
    fontSize: 18,
    color: colors.primary[500],
    lineHeight: 1.3,
    letterSpacing: 0.3,
    paddingVertical: 5
  },
  h2: {
    fontFamily: 'open-sans',
    fontWeight: 700,
    fontSize: 16,
    color: colors.primary[500],
    lineHeight: 1.3,
    letterSpacing: 0.3,
    paddingVertical: 5
  },
  h3: {
    fontFamily: 'open-sans',
    fontWeight: 700,
    fontSize: 14,
    color: colors.primary[500],
    lineHeight: 1.3,
    letterSpacing: 0.3,
    paddingVertical: 5
  },
  caption: {
    fontFamily: 'open-sans',
    fontStyle: 'italic',
    fontWeight: 300,
    fontSize: 11,
    paddingVertical: 5
  },
  locationImg: {
    width: 'auto',
    height: 200,
    border: 1,
    borderStyle: 'solid',
    borderColor: colors.black
  },
  graphImg: {
    width: 'auto',
    height: 120
  },
  flexContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'space-between'
  },
  flexContainerItem: {
    padding: 5
  },
  locationImageContainer: {
    // maxWidth: '50vw'
    width: 375
  },
  graphImageContainer: {
    // maxWidth: '50vw'
    width: 375
  },
  pdfViewerContainer: {
    height: '75vh'
  }
});

Font.register({
  family: 'open-sans',
  format: 'truetype',
  fonts: [
    { src: pdfFontLight, fontWeight: 300 },
    { src: pdfFontRegular },
    { src: pdfFontSemiBold, fontWeight: 600 },
    { src: pdfFontBold, fontWeight: 700 },
    { src: pdfFontExtraBold, fontWeight: 800 },

    { src: pdfFontLightItalic, fontWeight: 300, fontStyle: 'italic' },
    { src: pdfFontItalic, fontStyle: 'italic' },
    { src: pdfFontSemiBoldItalic, fontWeight: 600, fontStyle: 'italic' },
    { src: pdfFontBoldItalic, fontWeight: 700, fontStyle: 'italic' },
    { src: pdfFontExtraBoldItalic, fontWeight: 800, fontStyle: 'italic' }
  ]
});
