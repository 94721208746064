import { useEffect, useReducer, useRef } from 'react';
import { fetchAssets, fetchBusinessUnits, fetchCustomers, fetchMenuItems, fetchPartners } from 'src/api';

import { Asset, BusinessUnit, Customer, MenuItem, Partner, User } from 'src/models';

export const EMPTY = '...';

export type MenuState = {
  partners: Record<Partner['id'], Partner>;
  customers: Record<Customer['id'], Customer>;
  businessUnits: Record<BusinessUnit['id'], BusinessUnit>;
  assets: Record<Asset['id'], Asset>;
  items: MenuItem[];
  grouped: Record<Partner['id'], MenuItem[]>;
};

type MenuAction =
  | { kind: Partner['__typename']; items: Partner[] | null }
  | { kind: Customer['__typename']; items: Customer[] | null }
  | { kind: BusinessUnit['__typename']; items: BusinessUnit[] | null }
  | { kind: Asset['__typename']; items: Asset[] | null }
  | { kind: Partner['__typename']; items: Partner[] | null }
  | { kind: MenuItem['__typename']; items: MenuItem[] | null }
  | { kind: 'RESET' };

export const getInitMenuState = (): MenuState => ({
  partners: {},
  customers: {},
  businessUnits: {},
  assets: {},
  items: [],
  grouped: {}
});

const handlePartnerAction = (
  state: MenuState,
  { items }: Extract<MenuAction, { kind: Partner['__typename'] }>
): MenuState =>
  items
    ? {
        ...state,
        partners: items.reduce((records, item) => {
          records[item.id] = item;
          return records;
        }, {} as MenuState['partners'])
      }
    : state;

const handleCustomerAction = (
  state: MenuState,
  { items }: Extract<MenuAction, { kind: Customer['__typename'] }>
): MenuState =>
  items
    ? {
        ...state,
        customers: items.reduce<MenuState['customers']>((records, item) => {
          records[item.id] = item;
          return records;
        }, {})
      }
    : state;

const handleBusinessUnitAction = (
  state: MenuState,
  { items }: Extract<MenuAction, { kind: BusinessUnit['__typename'] }>
): MenuState =>
  items
    ? {
        ...state,
        businessUnits: items.reduce<MenuState['businessUnits']>((records, item) => {
          records[item.id] = item;
          return records;
        }, {})
      }
    : state;

const handleAssetAction = (
  state: MenuState,
  { items }: Extract<MenuAction, { kind: Asset['__typename'] }>
): MenuState =>
  items
    ? {
        ...state,
        assets: items.reduce<MenuState['assets']>((records, item) => {
          records[item.id] = item;
          return records;
        }, {})
      }
    : state;

const handleMenuAction = (
  state: MenuState,
  { items }: Extract<MenuAction, { kind: MenuItem['__typename'] }>
): MenuState =>
  items
    ? {
        ...state,
        items,
        grouped: items.reduce((grouped, item) => {
          const id = item.partner ?? EMPTY;
          if (id in grouped) grouped[id].push(item);
          else grouped[id] = [item];
          return grouped;
        }, {})
      }
    : state;

const reducers: Record<MenuAction['kind'], (state: MenuState, action: MenuAction) => MenuState> = {
  Partner: handlePartnerAction,
  Customer: handleCustomerAction,
  BusinessUnit: handleBusinessUnitAction,
  Asset: handleAssetAction,
  MenuItem: handleMenuAction,
  RESET: getInitMenuState
};

const menuReducer = (state: MenuState, action: MenuAction): MenuState => {
  const reducer = reducers[action.kind];
  if (!reducer) throw new Error(`Unknown action: ${action.kind}`);
  return reducer(state, action);
};

const useMenuState = (userId: User['id']) => {
  const [state, dispatch] = useReducer(menuReducer, getInitMenuState());
  const user = useRef<User['id']>(userId);

  useEffect(() => {
    if (user.current !== userId) {
      user.current = userId;
      return dispatch({ kind: 'RESET' });
    }

    fetchMenuItems().then(
      (response) => response.kind === 'SUCCESS' && dispatch({ kind: 'MenuItem', items: response.data })
    );
    fetchPartners().then(
      (response) => response.kind === 'SUCCESS' && dispatch({ kind: 'Partner', items: response.data })
    );
    fetchCustomers().then(
      (response) => response.kind === 'SUCCESS' && dispatch({ kind: 'Customer', items: response.data })
    );
    fetchBusinessUnits().then(
      (response) => response.kind === 'SUCCESS' && dispatch({ kind: 'BusinessUnit', items: response.data })
    );
    fetchAssets().then(
      (response) => response.kind === 'SUCCESS' && dispatch({ kind: 'Asset', items: response.data })
    );
  }, [user, userId]);

  return state;
};

export default useMenuState;
