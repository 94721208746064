import { GraphQLResult } from '@aws-amplify/api-graphql';
import { API, graphqlOperation } from 'aws-amplify';

import * as api from 'src/models/API';

import * as M from './mutations';
import * as Q from './queries';

type SuccessResponse<R> = { kind: 'SUCCESS'; data: R | null };
type CanceledResponse = { kind: 'CANCELED' };
type ErrorResponse = { kind: 'ERROR'; error: any };
type Result<R> = Promise<SuccessResponse<R> | CanceledResponse | ErrorResponse>;
type ResponseFactory<R> = {
  request: () => Result<R>;
  cancel: () => boolean;
};

/**
 * Generic GraphQL async operation handler.
 * @param { Q | M } module - GraphQL Query or Mutation module
 * @param { string } operation - GraphQL operation query, should be part of Q or M
 * @param { V } variables - (optional) GraphQL operation variables
 * @returns { R } data OR undefined
 */
function operation<V, R>(module: typeof Q | typeof M, operation: string, variables?: V): ResponseFactory<R> {
  const oper = API.graphql(graphqlOperation(module[operation], variables)) as Promise<GraphQLResult<R>>;
  const cancel = () => API.cancel(oper);

  const request = async <R>(): Result<R> => {
    try {
      const response = await oper;
      const data = response.data[operation];
      return { kind: 'SUCCESS', data };
    } catch (error) {
      if (error?.name === 'CanceledError') {
        console.warn(`Operation ${operation} canceled!`);
        return { kind: 'CANCELED' };
      } else {
        console.error(`Operation failed: ${operation} with ${variables}`, error);
        return { kind: 'ERROR', error };
      }
    }
  };

  return {
    request,
    cancel
  };
}

// QUERIES
/* prettier-ignore */ export const fetchMenuItems = async () => operation<never, api.MenuItem[]>(Q, 'getMenuItems').request();
/* prettier-ignore */ export const fetchPartners = async () => operation<never, api.Partner[]>(Q, 'getPartners').request();
/* prettier-ignore */ export const fetchCustomers = async () => operation<never, api.Customer[]>(Q, 'getCustomers').request();
/* prettier-ignore */ export const fetchBusinessUnits = async () => operation<never, api.BusinessUnit[]>(Q, 'getBusinessUnits').request();
/* prettier-ignore */ export const fetchAssets = async () => operation<never, api.Asset[]>(Q, 'getAssets').request();
/* prettier-ignore */ export const fetchDashboardItems = async (params: api.GetDashboardItemsQueryVariables) => operation<api.GetDashboardItemsQueryVariables, api.DashboardItem[]> (Q, 'getDashboardItems', params).request();
/* prettier-ignore */ export const fetchDashboardConfig = async () => operation<never, api.DashboardConfig>(Q, 'getDashboardConfig').request();
/* prettier-ignore */ export const fetchReports = async (params: api.GetReportsQueryVariables) => operation<api.GetReportsQueryVariables, api.Report[]>(Q, 'getReports', params).request();
/* prettier-ignore */ export const fetchHistory = (params: api.GetHistoryQueryVariables): ResponseFactory<api.History[]> => operation(Q, 'getHistory', params);

// MUTATIONS
/* prettier-ignore */ export const updateOverviewImage = async (params: api.SetOverviewImageMutationVariables) => operation(M, 'setOverviewImage', params);
/* prettier-ignore */ export const updateLocationImage = async (params: api.SetLocationImageMutationVariables) => operation(M, 'setLocationImage', params);
/* prettier-ignore */ export const updateLocationPosition = async (params: api.SetLocationPositionMutationVariables) => operation(M, 'setLocationPosition', params);
/* prettier-ignore */ export const updateCrackImage = async (params: api.SetCrackImageMutationVariables) => operation(M, 'setCrackImage', params);
/* prettier-ignore */ export const updatePipeImage = async (params: api.SetPipeImageMutationVariables) => operation(M, 'setPipeImage', params);
/* prettier-ignore */ export const updatePipeInfo = async (params: api.SetPipeInfoMutationVariables) => operation(M, 'setPipeInfo', params);
