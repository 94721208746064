import { Theme } from '@mui/material';
import { CSSProperties } from 'react';

import { LightTheme } from './schemes/LightTheme';

export function themeCreator(theme: string): Theme {
  return themeMap[theme];
}

export type Palette = {
  50: CSSProperties['color'];
  100: CSSProperties['color'];
  200: CSSProperties['color'];
  300: CSSProperties['color'];
  400: CSSProperties['color'];
  500: CSSProperties['color'];
  600: CSSProperties['color'];
  700: CSSProperties['color'];
  800: CSSProperties['color'];
  900: CSSProperties['color'];
};

type ThemeProps = {
  colors: {
    primary: Palette;
    success: Palette;
    warning: Palette;
    error: Palette;
    info: Palette;
    grey: Palette;
    black: CSSProperties['color'];
    white: CSSProperties['color'];
    activityStatus: {
      NO: CSSProperties['color'];
      MODERATE: CSSProperties['color'];
      SIGNIFICANT: CSSProperties['color'];
      OFFLINE: CSSProperties['color'];
      PENDING: CSSProperties['color'];
      ARCHIVED: CSSProperties['color'];
    };
    shadows: {
      primary: CSSProperties['boxShadow'];
      success: CSSProperties['boxShadow'];
      warning: CSSProperties['boxShadow'];
      error: CSSProperties['boxShadow'];
      info: CSSProperties['boxShadow'];
      grey: CSSProperties['boxShadow'];
    };
  };
  borders: {
    borderRadiusSm: CSSProperties['borderRadius'];
    borderRadius: CSSProperties['borderRadius'];
    borderRadiusLg: CSSProperties['borderRadius'];
    borderRadiusXl: CSSProperties['borderRadius'];
    primary: CSSProperties['border'];
    white: CSSProperties['border'];
    accent: CSSProperties['border'];
    disabled: CSSProperties['border'];
  };
  sidebar: {
    background: CSSProperties['color'];
    boxShadow: CSSProperties['boxShadow'];
    width: CSSProperties['width'];
  };
  header: {
    height: CSSProperties['height'];
    background: CSSProperties['color'];
    boxShadow: CSSProperties['boxShadow'];
    textColor: CSSProperties['color'];
  };
  footer: {
    height: CSSProperties['height'];
    background: CSSProperties['color'];
    boxShadow: CSSProperties['boxShadow'];
    textColor: CSSProperties['color'];
  };
};

declare module '@mui/material/styles' {
  interface Theme extends ThemeProps {}
  interface ThemeOptions extends ThemeProps {}
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    accent: true;
    'accent-outlined': true;
    'accent-text': true;
  }
}

declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    accent: true;
  }
}

const themeMap: { [key: string]: Theme } = {
  LightTheme
};
