import { PictureAsPdf } from "@mui/icons-material";
import { Button, useTheme } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { Storage } from "aws-amplify";
import { Downloadable } from "src/content/pages/Download";

interface IDownloadButtonProps {
  downloadable: Downloadable;
}

const DownloadButton: FC<IDownloadButtonProps> = ({
  downloadable
}) => {
  const [fileUrl, setFileUrl] = useState<string | null>(null);
  const theme = useTheme();

  const urlLoader = async (fileKey: string) => {
    await Storage.get(downloadable.filekey, { level: 'public' }).then(url => setFileUrl(url)).catch(err => console.error(`Failed to get url: ${fileKey}`, err));
  }

  useEffect(() => {
      urlLoader(downloadable.filekey);
      let interval = setInterval(() => {urlLoader(downloadable.filekey); }, 1000 * 60 * 14 //14 minutes
      );

      return () => {
        clearInterval(interval);
      };
  }, []);

  return (
    <Button href={fileUrl} target="_blank" startIcon={<PictureAsPdf sx={{ color: theme.palette.error.main }} />} sx={{justifyContent: "flex-start"}}>
      {downloadable.filename}
    </Button>
  )
};

export default DownloadButton;
