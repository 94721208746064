import { RouteObject } from 'react-router';
import { Navigate } from 'react-router-dom';

import Loader from 'src/components/LazyLoader';
import BaseLayout from 'src/layouts/BaseLayout';
import DashboardLayout from 'src/layouts/DashboardLayout';
import Download from './content/pages/Download';

// Pages
const Home = Loader(() => import('src/content/pages/Home'));
const Dashboard = Loader(() => import('src/content/pages/Dashboard'));
const Account = Loader(() => import('src/content/pages/Account'));
const Notifications = Loader(() => import('src/content/pages/Notifications'));
const FAQ = Loader(() => import('src/content/pages/FAQ'));
const SwitchRole = Loader(() => import('src/content/pages/SwitchRole'));

// Status
const Status404 = Loader(() => import('src/content/pages/Status/Status404'));
const Status500 = Loader(() => import('src/content/pages/Status/Status500'));
const StatusComingSoon = Loader(() => import('src/content/pages/Status/ComingSoon'));
const StatusMaintenance = Loader(() => import('src/content/pages/Status/Maintenance'));

// Paths
export const routePaths = {
  HOME: '/home',
  DASHBOARD: '/dashboard/:id',
  NOTIFICATIONS: '/notifications',
  PROFILE: '/profile',
  PROFILE_SETTINGS: '/profile/settings',
  PROFILE_SWTICH_ROLE: '/profile/switchrole',
  FAQ: '/faq',
  DOWNLOAD: '/download',
  FALLBACK: '/*'
}

// Routing
const publicRoutes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      { path: '/', element: < Navigate to="home" replace /> }
    ]
  }
]

const protectedRoutes: RouteObject[] = [
  {
    path: '',
    element: <DashboardLayout />,
    children: [
      { path: routePaths.HOME, element: <Home /> },
      { path: routePaths.DASHBOARD, element: <Dashboard /> },
      { path: routePaths.NOTIFICATIONS, element: <Notifications /> },
      { path: routePaths.PROFILE, element: <Navigate to='settings' replace /> },
      { path: routePaths.PROFILE_SETTINGS, element: <Account /> },
      { path: routePaths.PROFILE_SWTICH_ROLE, element: <SwitchRole />},
      { path: routePaths.FAQ, element: <FAQ /> },
      { path: routePaths.DOWNLOAD, element: <Download /> }
    ]
  }
]

const fallbackRoutes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      { path: routePaths.FALLBACK, element: <Status404 /> }
    ]
  }
]

const routes: RouteObject[] = [
  ...publicRoutes,
  ...protectedRoutes,
  ...fallbackRoutes,
]


export default routes;