import { Button, Divider } from '@mui/material';

import { useTranslation } from 'react-i18next';
import SidebarMenuTree from './SidebarMenuTree';
import { useNavigate } from 'react-router-dom';

const SidebarMenu = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <Button
        size="large"
        variant="text"
        sx={{
          justifyContent: 'flex-start'
        }}
        onClick={() => navigate('home')}
      >
        {t('label.home')}
      </Button>
      <Divider />

      <SidebarMenuTree />
    </>
  );
};

export default SidebarMenu;
