/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type OverviewImageInput = {
  idx: number,
  asset: string,
  name: string,
  ext: string,
  relation: string,
};

export type LocationImageInput = {
  controlUnit: string,
  name: string,
  ext: string,
  relation?: string | null,
};

export type LocationPositionInput = {
  controlUnit: string,
  x: number,
  y: number,
};

export type CrackImageInput = {
  strip: string,
  name: string,
  ext: string,
};

export type PipeImageInput = {
  strip: string,
  name: string,
  ext: string,
};

export type PipeInfoInput = {
  strip: string,
  mountedType?: MountedType | null,
  isReversed?: boolean | null,
  startAngle?: number | null,
  radius?: number | null,
};

export enum MountedType {
  FLAT = "FLAT",
  PIPE = "PIPE",
}


export type DashboardConfig = {
  __typename: "DashboardConfig",
  tosVersion: string,
  isMaintenance: boolean,
};

export type Asset = {
  __typename: "Asset",
  id: string,
  businessUnit: string,
  customer: string,
  partner?: string | null,
  state: State,
  name: string,
  type: AssetType,
  overviews?:  Array<OverviewImage > | null,
  isWatermelon?: boolean | null,
};

export enum State {
  ACTIVE = "ACTIVE",
  DISABLED = "DISABLED",
  MAINTENANCE = "MAINTENANCE",
  LOOSE = "LOOSE",
  HIDDEN = "HIDDEN",
  BROKEN = "BROKEN",
  ARCHIVED = "ARCHIVED",
}


export enum AssetType {
  OBJECT = "OBJECT",
  ASSET = "ASSET",
  BRIDGE = "BRIDGE",
  CRANE = "CRANE",
}


export type OverviewImage = {
  __typename: "OverviewImage",
  id: string,
  type: ImageType,
  name: string,
  ext: string,
  relation: string,
  idx: number,
};

export enum ImageType {
  OVERVIEW = "OVERVIEW",
  LOCATION = "LOCATION",
  CRACK = "CRACK",
  PIPE = "PIPE",
  AVATAR = "AVATAR",
  FAQ = "FAQ",
}


export type BusinessUnit = {
  __typename: "BusinessUnit",
  id: string,
  customer: string,
  partner?: string | null,
  state: State,
  name: string,
};

export type Customer = {
  __typename: "Customer",
  id: string,
  partner?: string | null,
  state: State,
  name: string,
};

export type Partner = {
  __typename: "Partner",
  id: string,
  state: State,
  name: string,
};

export type MenuItem = {
  __typename: "MenuItem",
  partner?: string | null,
  customer: string,
  businessUnit: string,
  assets?: Array< string > | null,
};

export type DashboardItem = {
  __typename: "DashboardItem",
  controlUnit: ControlUnit,
  strips:  Array<Strip >,
};

export type ControlUnit = {
  __typename: "ControlUnit",
  id: string,
  devEUI: string,
  location?: LocationImage | null,
  version: string,
  installationDate: string,
  isOnline?: boolean | null,
};

export type LocationImage = {
  __typename: "LocationImage",
  id: string,
  type: ImageType,
  name: string,
  ext: string,
  relation?: string | null,
  coord?: Coord | null,
};

export type Coord = {
  __typename: "Coord",
  id?: string | null,
  x: number,
  y: number,
};

export type Strip = {
  __typename: "Strip",
  id: string,
  controlUnit: string,
  state: State,
  image?: CrackImage | null,
  pipeImage?: PipeImage | null,
  location: string,
  stripType: StripType,
  installationDate: string,
};

export type CrackImage = {
  __typename: "CrackImage",
  id: string,
  type: ImageType,
  name: string,
  ext: string,
  coords?:  Array<Coord | null > | null,
};

export type PipeImage = {
  __typename: "PipeImage",
  id: string,
  type: ImageType,
  name: string,
  ext: string,
  info: FlexInfo,
};

export type FlexInfo = {
  __typename: "FlexInfo",
  mountedType?: MountedType | null,
  isReversed?: boolean | null,
  startAngle?: number | null,
  radius?: number | null,
};

export enum StripType {
  RIGID = "RIGID",
  FLEX = "FLEX",
}


export type Report = AnalysisReport | StripReport | ControlUnitReport | AssetReport


export type AnalysisReport = {
  __typename: "AnalysisReport",
  id: string,
  timestamp?: string | null,
  value?: number | null,
  activity?: Activity | null,
  delayedTimestamp?: string | null,
  delayedValue?: number | null,
  delayedActivity?: Activity | null,
  connectivity?: Connectivity | null,
};

export enum Activity {
  ARCHIVED = "ARCHIVED",
  NO = "NO",
  MODERATE = "MODERATE",
  SIGNIFICANT = "SIGNIFICANT",
  PENDING = "PENDING",
  OFFLINE = "OFFLINE",
}


export type Connectivity = {
  __typename: "Connectivity",
  daily?: number | null,
  weekly?: number | null,
};

export type StripReport = {
  __typename: "StripReport",
  id: string,
  range?: Range | null,
  value?: number | null,
  activity?: Activity | null,
  delayedRange?: Range | null,
  delayedValue?: number | null,
  delayedActivity?: Activity | null,
  connectivity?: Connectivity | null,
  config?: Config | null,
};

export type Range = {
  __typename: "Range",
  from?: string | null,
  to?: string | null,
};

export type Config = {
  __typename: "Config",
  average?: number | null,
  algoType?: string | null,
  beginDate?: string | null,
  minBoundary?: number | null,
  maxBoundary?: number | null,
  lowerThreshold?: number | null,
  upperThreshold?: number | null,
};

export type ControlUnitReport = {
  __typename: "ControlUnitReport",
  id: string,
  range?: Range | null,
  value?: number | null,
  activity?: Activity | null,
  delayedRange?: Range | null,
  delayedValue?: number | null,
  delayedActivity?: Activity | null,
  connectivity?: Connectivity | null,
  voltage?: number | null,
  battery?: number | null,
  lifetime?: number | null,
};

export type AssetReport = {
  __typename: "AssetReport",
  id: string,
  range?: Range | null,
  value?: number | null,
  activity?: Activity | null,
  delayedRange?: Range | null,
  delayedValue?: number | null,
  delayedActivity?: Activity | null,
  connectivity?: Connectivity | null,
  stat?: Stat | null,
};

export type Stat = {
  __typename: "Stat",
  NO: number,
  MODERATE: number,
  SIGNIFICANT: number,
  PENDING: number,
  OFFLINE: number,
  totalStrips: number,
  totalControlUnits: number,
};

export type History = {
  __typename: "History",
  controlUnit: string,
  strip: string,
  zone: string,
  points?:  Array<HistoryPoint > | null,
};

export type HistoryPoint = {
  __typename: "HistoryPoint",
  datetime: string,
  value?: number | null,
};

export type SetOverviewImageMutationVariables = {
  input: OverviewImageInput,
};

export type SetOverviewImageMutation = {
  setOverviewImage: number,
};

export type SetLocationImageMutationVariables = {
  input: LocationImageInput,
};

export type SetLocationImageMutation = {
  setLocationImage: number,
};

export type SetLocationPositionMutationVariables = {
  input: LocationPositionInput,
};

export type SetLocationPositionMutation = {
  setLocationPosition: number,
};

export type SetCrackImageMutationVariables = {
  input: CrackImageInput,
};

export type SetCrackImageMutation = {
  setCrackImage: number,
};

export type SetPipeImageMutationVariables = {
  input: PipeImageInput,
};

export type SetPipeImageMutation = {
  setPipeImage: number,
};

export type SetPipeInfoMutationVariables = {
  input: PipeInfoInput,
};

export type SetPipeInfoMutation = {
  setPipeInfo: number,
};

export type GetDashboardConfigQueryVariables = {
};

export type GetDashboardConfigQuery = {
  getDashboardConfig:  {
    __typename: "DashboardConfig",
    tosVersion: string,
    isMaintenance: boolean,
  },
};

export type GetAssetsQueryVariables = {
};

export type GetAssetsQuery = {
  getAssets?:  Array< {
    __typename: "Asset",
    id: string,
    businessUnit: string,
    customer: string,
    partner?: string | null,
    state: State,
    name: string,
    type: AssetType,
    overviews?:  Array< {
      __typename: "OverviewImage",
      id: string,
      type: ImageType,
      name: string,
      ext: string,
      relation: string,
      idx: number,
    } > | null,
    isWatermelon?: boolean | null,
  } > | null,
};

export type GetBusinessUnitsQueryVariables = {
};

export type GetBusinessUnitsQuery = {
  getBusinessUnits?:  Array< {
    __typename: "BusinessUnit",
    id: string,
    customer: string,
    partner?: string | null,
    state: State,
    name: string,
  } > | null,
};

export type GetCustomersQueryVariables = {
};

export type GetCustomersQuery = {
  getCustomers?:  Array< {
    __typename: "Customer",
    id: string,
    partner?: string | null,
    state: State,
    name: string,
  } > | null,
};

export type GetPartnersQueryVariables = {
};

export type GetPartnersQuery = {
  getPartners?:  Array< {
    __typename: "Partner",
    id: string,
    state: State,
    name: string,
  } > | null,
};

export type GetMenuItemsQueryVariables = {
};

export type GetMenuItemsQuery = {
  getMenuItems?:  Array< {
    __typename: "MenuItem",
    partner?: string | null,
    customer: string,
    businessUnit: string,
    assets?: Array< string > | null,
  } > | null,
};

export type GetDashboardItemsQueryVariables = {
  asset: string,
};

export type GetDashboardItemsQuery = {
  getDashboardItems?:  Array< {
    __typename: "DashboardItem",
    controlUnit:  {
      __typename: "ControlUnit",
      id: string,
      devEUI: string,
      location?:  {
        __typename: "LocationImage",
        id: string,
        type: ImageType,
        name: string,
        ext: string,
        relation?: string | null,
        coord?:  {
          __typename: "Coord",
          id?: string | null,
          x: number,
          y: number,
        } | null,
      } | null,
      version: string,
      installationDate: string,
      isOnline?: boolean | null,
    },
    strips:  Array< {
      __typename: "Strip",
      id: string,
      controlUnit: string,
      state: State,
      image?:  {
        __typename: "CrackImage",
        id: string,
        type: ImageType,
        name: string,
        ext: string,
        coords?:  Array< {
          __typename: "Coord",
          id?: string | null,
          x: number,
          y: number,
        } | null > | null,
      } | null,
      pipeImage?:  {
        __typename: "PipeImage",
        id: string,
        type: ImageType,
        name: string,
        ext: string,
        info:  {
          __typename: "FlexInfo",
          mountedType?: MountedType | null,
          isReversed?: boolean | null,
          startAngle?: number | null,
          radius?: number | null,
        },
      } | null,
      location: string,
      stripType: StripType,
      installationDate: string,
    } >,
  } > | null,
};

export type GetReportsQueryVariables = {
  type: string,
  id: string,
};

export type GetReportsQuery = {
  getReports:  Array<( {
      __typename: "AnalysisReport",
      id: string,
      timestamp?: string | null,
      value?: number | null,
      activity?: Activity | null,
      delayedTimestamp?: string | null,
      delayedValue?: number | null,
      delayedActivity?: Activity | null,
      connectivity?:  {
        __typename: string,
        daily?: number | null,
        weekly?: number | null,
      } | null,
    } | {
      __typename: "StripReport",
      id: string,
      range?:  {
        __typename: string,
        from?: string | null,
        to?: string | null,
      } | null,
      value?: number | null,
      activity?: Activity | null,
      delayedRange?:  {
        __typename: string,
        from?: string | null,
        to?: string | null,
      } | null,
      delayedValue?: number | null,
      delayedActivity?: Activity | null,
      connectivity?:  {
        __typename: string,
        daily?: number | null,
        weekly?: number | null,
      } | null,
      config?:  {
        __typename: string,
        average?: number | null,
        algoType?: string | null,
        beginDate?: string | null,
        minBoundary?: number | null,
        maxBoundary?: number | null,
        lowerThreshold?: number | null,
        upperThreshold?: number | null,
      } | null,
    } | {
      __typename: "ControlUnitReport",
      id: string,
      range?:  {
        __typename: string,
        from?: string | null,
        to?: string | null,
      } | null,
      value?: number | null,
      activity?: Activity | null,
      delayedRange?:  {
        __typename: string,
        from?: string | null,
        to?: string | null,
      } | null,
      delayedValue?: number | null,
      delayedActivity?: Activity | null,
      connectivity?:  {
        __typename: string,
        daily?: number | null,
        weekly?: number | null,
      } | null,
      voltage?: number | null,
      battery?: number | null,
      lifetime?: number | null,
    } | {
      __typename: "AssetReport",
      id: string,
      range?:  {
        __typename: string,
        from?: string | null,
        to?: string | null,
      } | null,
      value?: number | null,
      activity?: Activity | null,
      delayedRange?:  {
        __typename: string,
        from?: string | null,
        to?: string | null,
      } | null,
      delayedValue?: number | null,
      delayedActivity?: Activity | null,
      connectivity?:  {
        __typename: string,
        daily?: number | null,
        weekly?: number | null,
      } | null,
      stat?:  {
        __typename: string,
        NO: number,
        MODERATE: number,
        SIGNIFICANT: number,
        PENDING: number,
        OFFLINE: number,
        totalStrips: number,
        totalControlUnits: number,
      } | null,
    }
  ) > | null,
};

export type GetHistoryQueryVariables = {
  controlUnit: string,
  strip: string,
  type: string,
  from: string,
  to: string,
};

export type GetHistoryQuery = {
  getHistory?:  Array< {
    __typename: "History",
    controlUnit: string,
    strip: string,
    zone: string,
    points?:  Array< {
      __typename: "HistoryPoint",
      datetime: string,
      value?: number | null,
    } > | null,
  } > | null,
};
