import PartnerTreeExpandIcon from '@mui/icons-material/AddTwoTone';
import PartnerTreeCollapseIcon from '@mui/icons-material/CloseTwoTone';
import EntityIcon from '@mui/icons-material/CorporateFareTwoTone';
import CollapseIcon from '@mui/icons-material/KeyboardArrowDownTwoTone';
import ExpandIcon from '@mui/icons-material/KeyboardArrowRightTwoTone';
import AssetIcon from '@mui/icons-material/LabelTwoTone';
import { Box, Skeleton, Typography, styled, useTheme } from '@mui/material';
import { TreeItem, TreeItemProps, treeItemClasses } from '@mui/x-tree-view/TreeItem';
import { TreeView } from '@mui/x-tree-view/TreeView';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useUser } from 'src/contexts/UserContext';
import { EMPTY } from 'src/hooks/MenuStateHook';
import { MenuItem, State } from 'src/models';

const PartnerItem = ({ id, ...props }: { id: string } & Partial<TreeItemProps>) => {
  const { menu } = useUser();

  const partner = menu.partners[id];

  return (
    <TreeItem
      nodeId={id}
      label={
        partner ? (
          <Typography variant="h6">
            {partner.name}
          </Typography>
        ) : (
          <Skeleton variant="text" />
        )
      }
      {...props}
      expandIcon={<PartnerTreeExpandIcon />}
      collapseIcon={<PartnerTreeCollapseIcon />}
    />
  );
};

const EntityItem = ({ item, ...props }: { item: MenuItem } & Partial<TreeItemProps>) => {
  const { menu } = useUser();

  const customer = menu.customers[item.customer];
  const businessUnit = menu.businessUnits[item.businessUnit];
  const state = customer?.state === State.ACTIVE ? businessUnit?.state : customer?.state;

  return (
    <TreeItem
      nodeId={item.businessUnit}
      className="entity-item"
      icon={<EntityIcon color={state === State.HIDDEN ? 'primary' : 'secondary'} />}
      label={
        <Box display="flex" flexDirection="column">
          {customer ? (
            <Typography variant="h6" fontWeight="bold">
              {customer.name}
            </Typography>
          ) : (
            <Skeleton variant="text" />
          )}
          {customer && businessUnit ? (
            customer.name !== businessUnit.name && (
              <Typography variant="subtitle2" fontWeight="normal">
                {businessUnit.name}
              </Typography>
            )
          ) : (
            <Skeleton variant="text" />
          )}
        </Box>
      }
      {...props}
    />
  );
};

const AssetItem = ({ id, ...props }: { id: string } & Partial<TreeItemProps>) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { menu } = useUser();

  const asset = menu.assets[id];

  return (
    <TreeItem
      sx={{
        [`.${treeItemClasses.content}.Mui-selected`]: {
          border: theme.borders.primary
        }
      }}
      nodeId={id}
      icon={<AssetIcon color={asset?.state === State.HIDDEN ? 'primary' : 'secondary'} />}
      label={
        asset ? (
          <Typography variant="h6" fontWeight="bold">
            {asset.name}
          </Typography>
        ) : (
          <Skeleton variant="text" />
        )
      }
      onClick={() => navigate(`/dashboard/${asset.id}`)}
      {...props}
    />
  );
};

const EntityTree = ({ items }: { items: MenuItem[] }) => {
  return (
    <>
      {items.map((item) => (
        <EntityItem key={item.businessUnit} item={item}>
          {item.assets.map((id) => (
            <AssetItem key={id} id={id} />
          ))}
        </EntityItem>
      ))}
    </>
  );
};

const SidebarMenuTree = () => {
  const theme = useTheme();

  const { id } = useParams();
  const { menu, access } = useUser();

  const [expanded, setExpanded] = useState<string[]>([]);
  const [selected, setSelected] = useState<string[]>([]);

  useEffect(() => {
    const asset = menu.assets[id];

    if (!asset) return;

    setExpanded([asset.businessUnit, asset.partner]);
    setSelected([asset.businessUnit, asset.partner, id]);
  }, [id, menu]);

  return (
    <TreeView
      aria-label="menu-tree"
      defaultCollapseIcon={<CollapseIcon />}
      defaultExpandIcon={<ExpandIcon />}
      selected={selected}
      expanded={expanded}
      onNodeToggle={(_, nodeIds: string[]) => setExpanded(nodeIds)}
      sx={{
        height: '100%',
        flexGrow: 1,
        maxWidth: theme.sidebar.width,
        overflowY: 'auto',
        padding: '12px'
      }}
    >
      {Object.entries(access.asStaff ? menu.grouped : { [EMPTY]: menu.items }).map(([id, items], idx) => {
        if (id === EMPTY || !access.asStaff) return <EntityTree key={`menu-tree-${idx}`} items={items} />;
        return (
          <PartnerItem key={id} id={id}>
            <EntityTree items={items} />
          </PartnerItem>
        );
      })}
    </TreeView>
  );
};

export default SidebarMenuTree;
