import { useEffect, useState } from 'react';

import { fetchDashboardItems } from 'src/api';
import { Asset, ControlUnit, Coord, LocationImage, Strip } from 'src/models';
import { dashboardItemComparator, stripComparator } from 'src/utils';

type AssetMarker = { id: ControlUnit['id']; coord: Coord };

export type DashboardState = {
  controlUnits: Record<ControlUnit['id'], ControlUnit>;
  strips: Record<Strip['id'], Strip>;
  tree: { controlUnit: ControlUnit['id']; strips: Strip['id'][] }[];
  treeIndex: Record<ControlUnit['id'], Strip['id'][]>;
  assetMarkers: Record<LocationImage['id'], AssetMarker[]>;
};

export const getInitDashboardState = (): DashboardState => ({
  controlUnits: {},
  strips: {},
  tree: [],
  treeIndex: {},
  assetMarkers: { UNASSIGNED: [] as AssetMarker[] }
});

const useDashboardState = (asset: Asset) => {
  const [state, setState] = useState<DashboardState>(getInitDashboardState);

  useEffect(() => {
    if (!asset) return;

    const fetchDashboard = async () => {
      const state = getInitDashboardState();

      state.assetMarkers = asset.overviews.reduce((record, image) => {
        record[image.relation] = [];
        return record;
      }, state.assetMarkers);

      const dashItems = await fetchDashboardItems({ asset: asset.id });

      if (dashItems.kind !== 'SUCCESS') return;

      for (let dashItem of dashItems.data.sort(dashboardItemComparator)) {
        const node: DashboardState['tree'][number] = {
          controlUnit: dashItem.controlUnit.id,
          strips: []
        };

        state.controlUnits[dashItem.controlUnit.id] = dashItem.controlUnit;

        dashItem.strips.sort(stripComparator).forEach((strip) => {
          state.strips[strip.id] = strip;
          node.strips.push(strip.id);
        });

        state.tree.push(node);
        state.treeIndex[node.controlUnit] = node.strips;

        const image = dashItem.controlUnit.location;
        if (image)
          state.assetMarkers[image.relation in state.assetMarkers ? image.relation : 'UNASSIGNED'].push({
            id: dashItem.controlUnit.id,
            coord: image.coord
          });
      }

      setState(state);
    };

    fetchDashboard();
  }, [asset]);

  return state;
};

export default useDashboardState;
