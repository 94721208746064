import { FC, createContext, useContext, useMemo, useState } from 'react';

import useMenuState, { MenuState } from 'src/hooks/MenuStateHook';
import {
  Tenant,
  User,
  checkTenant,
  getInitials,
  getTenantList,
  getUserTenant
} from 'src/models';

import { useApp } from './AppContext';

export type Access = {
  available: Tenant[];

  isAdmin: boolean;
  isStaff: boolean;
  isArchivist: boolean;
  isCustomer: boolean;
  isEnd: boolean;
  isBasic: boolean;

  asTenant: Tenant;
  asAdmin: boolean;
  asStaff: boolean;
  asArchivist: boolean;
  asCustomer: boolean;
  asEnd: boolean;
  asBasic: boolean;

  change: (t: Tenant) => void;
};

interface IUserContext {
  user: User;
  menu: MenuState;
  access: Access;
}

const UserContext = createContext<IUserContext>({} as IUserContext);

const UserProvider: FC = ({ children }) => {
  const { cognito } = useApp();

  const user: User = useMemo(
    () => ({
      id: cognito.attributes.sub, // cognito id
      email: cognito.attributes.email,
      givenName: cognito.attributes.given_name,
      familyName: cognito.attributes.family_name,
      companyName: cognito.attributes['custom:company'],
      tosVersion: cognito.attributes['custom:tos_version'],
      picture: cognito.attributes.picture,
      initials: getInitials(cognito.attributes.given_name, cognito.attributes.family_name)
    }),
    [cognito]
  );

  const menu = useMenuState(user.id);

  const tenant = getUserTenant(
    cognito.getSignInUserSession().getAccessToken().payload['cognito:groups'] ?? []
  );
  const prevAsTenant = localStorage.getItem('tenant') as Tenant;
  const [asTenant, setAsTenant] = useState<Tenant>(checkTenant(tenant, prevAsTenant) ? prevAsTenant : tenant);

  const access = {
    available: getTenantList(tenant),

    isAdmin: checkTenant(tenant, Tenant.ADMIN),
    isStaff: checkTenant(tenant, Tenant.STAFF),
    isArchivist: checkTenant(tenant, Tenant.ARCHIVIST),
    isCustomer: checkTenant(tenant, Tenant.CUSTOMER),
    isEnd: checkTenant(tenant, Tenant.END),
    isBasic: checkTenant(tenant, Tenant.BASIC),

    asAdmin: checkTenant(asTenant, Tenant.ADMIN),
    asStaff: checkTenant(asTenant, Tenant.STAFF),
    asArchivist: checkTenant(asTenant, Tenant.ARCHIVIST),
    asCustomer: checkTenant(asTenant, Tenant.CUSTOMER),
    asEnd: checkTenant(asTenant, Tenant.END),
    asBasic: checkTenant(asTenant, Tenant.BASIC),

    asTenant,
    change: (t: Tenant) => checkTenant(tenant, t) && setAsTenant(t)
  };

  return (
    <UserContext.Provider
      value={{
        user,
        access,
        menu
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
export const useUser = (): IUserContext => useContext(UserContext);

export default UserProvider;
