import DownloadIcon from '@mui/icons-material/FileDownloadTwoTone';
import FAQIcon from '@mui/icons-material/QuestionMarkRounded';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import ActionButton from 'src/components/ActionButton';

const HeaderActionsBox = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <ActionButton text={t('label.download')} icon={DownloadIcon} onClick={() => navigate('/download')} />
      <ActionButton text={t('label.faq')} icon={FAQIcon} onClick={() => navigate('/faq')} />
    </>
  );
};

export default HeaderActionsBox;
