import SupportIcon from '@mui/icons-material/ContactSupportTwoTone';
import { Box, Button, IconButton, Link, Tooltip, Typography, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';

import pk from '../../../package.json';

const FooterWrapper = styled(Box)(({ theme }) => ({
  background: theme.footer.background,
  color: theme.footer.textColor,
  height: theme.footer.height,
  maxHeight: theme.footer.height,
  position: 'fixed',
  width: '100%',
  bottom: 0,
  zIndex: theme.zIndex.drawer + 1,
  display: 'flex',
  alignItems: 'center',
  justifyItems: 'center',
  justifyContent: 'space-between'
}));

const Footer = () => {
  const { t } = useTranslation();

  const contactUs = () => (window.location.href = 'mailto:' + t('contact.email'));

  return (
    <FooterWrapper>
      <Box px={2}>
        <Button color="inherit" variant="text" size="small" title={t('label.contact')} onClick={contactUs} startIcon={ <SupportIcon />}>
          {t('label.contact')}
        </Button>
      </Box>
      <Box px={2}>
        <Typography variant="body2" color="inherit">
          &copy; {new Date().getFullYear()}
          {`, `}
          <Tooltip title={`Version ${pk.version}`} arrow placement="top">
            <Link href="https://villari.nl" target="_blank" rel="noopener noreferrer" color="inherit">
              Villari B.V.
            </Link>
          </Tooltip>
        </Typography>
      </Box>
    </FooterWrapper>
  );
};

export default Footer;
