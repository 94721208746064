/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../models/API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const setOverviewImage = /* GraphQL */ `mutation SetOverviewImage($input: OverviewImageInput!) {
  setOverviewImage(input: $input)
}
` as GeneratedMutation<
  APITypes.SetOverviewImageMutationVariables,
  APITypes.SetOverviewImageMutation
>;
export const setLocationImage = /* GraphQL */ `mutation SetLocationImage($input: LocationImageInput!) {
  setLocationImage(input: $input)
}
` as GeneratedMutation<
  APITypes.SetLocationImageMutationVariables,
  APITypes.SetLocationImageMutation
>;
export const setLocationPosition = /* GraphQL */ `mutation SetLocationPosition($input: LocationPositionInput!) {
  setLocationPosition(input: $input)
}
` as GeneratedMutation<
  APITypes.SetLocationPositionMutationVariables,
  APITypes.SetLocationPositionMutation
>;
export const setCrackImage = /* GraphQL */ `mutation SetCrackImage($input: CrackImageInput!) {
  setCrackImage(input: $input)
}
` as GeneratedMutation<
  APITypes.SetCrackImageMutationVariables,
  APITypes.SetCrackImageMutation
>;
export const setPipeImage = /* GraphQL */ `mutation SetPipeImage($input: PipeImageInput!) {
  setPipeImage(input: $input)
}
` as GeneratedMutation<
  APITypes.SetPipeImageMutationVariables,
  APITypes.SetPipeImageMutation
>;
export const setPipeInfo = /* GraphQL */ `mutation SetPipeInfo($input: PipeInfoInput!) {
  setPipeInfo(input: $input)
}
` as GeneratedMutation<
  APITypes.SetPipeInfoMutationVariables,
  APITypes.SetPipeInfoMutation
>;
