import { Asset, BusinessUnit, Customer } from 'src/models';

export function getEntityName(customer: Customer, businessUnit: BusinessUnit): string {
  if (!customer || !businessUnit) return '';
  return customer.name !== businessUnit.name ? `${customer.name}: ${businessUnit.name}` : customer.name;
}

export function getAssetName(asset: Asset): string {
  return `${asset.type.toCapitalCase()}: ${asset.name}`;
}

export function getTitle(customer: Customer, businessUnit: BusinessUnit, label?: string): string {
  const title = getEntityName(customer, businessUnit);
  if (!label) return title;
  return title ? `${title} - ${label}` : label;
}
