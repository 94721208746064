import { SvgIconComponent } from '@mui/icons-material';
import { Button, IconButton, Theme, useMediaQuery} from '@mui/material';
import _ from 'lodash';
import { MouseEventHandler } from 'react';

interface IActionButtonProps {
  icon: SvgIconComponent;
  text?: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
}

const ActionButton = ({ icon: Icon, text, onClick }: IActionButtonProps) => {
  const isText = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  
  return isText && !_.isEmpty(text) ? (
    <Button variant="accent-text" sx={{ fontSize: '15px' }} onClick={onClick}>
      {text}
    </Button>
  ) : (
    <IconButton color="accent" onClick={onClick}>
      <Icon color="inherit" />
    </IconButton>
  );
};

export default ActionButton;
