import { Box, styled } from '@mui/material';
import { FC } from 'react';
import { Outlet } from 'react-router-dom';

import Footer from './Footer';
import Header from './Header';
import Sidebar from './Sidebar';

const Content = styled(Box)(({ theme }) => ({
  minHeight: `calc(100vh - (${theme.header.height} + ${theme.footer.height} + 1px))`,
  display: 'block',
  position: 'relative',
  zIndex: 5,
  paddingTop: theme.header.height,
  paddingBottom: theme.footer.height,
  [theme.breakpoints.up('md')]: {
    marginLeft: theme.sidebar.width
  }
}));

const DashboardLayout: FC = () => {
  return (
    <Box height="100%" flex={1}>
      <Header />
      <Sidebar />
      <Content>
        <Outlet />
      </Content>
      <Footer />
    </Box>
  );
};

export default DashboardLayout;
