import { AmplifyUser } from '@aws-amplify/ui';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { Theme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { FC, createContext, useContext, useEffect, useState } from 'react';

import * as api from 'src/api';
import TermsOfService from 'src/components/TermsOfService';
import Throbber from 'src/components/Throbber';
import StatusMaintenance from 'src/content/pages/Status/Maintenance';
import { DashboardConfig } from 'src/models';

interface IAppContext {
  cognito: AmplifyUser;
  config: DashboardConfig;
  sidebar: {
    isOpen: boolean;
    isPermanent: boolean;
    openHandler: () => void;
    closeHandler: () => void;
  };
}

const AppContext = createContext<IAppContext>({} as IAppContext);

const AppProvider: FC = ({ children }) => {
  const { user } = useAuthenticator((context) => [context.user]);

  // Sidebar
  const sidebarIsPermanent = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const [sidebarIsOpen, setSidebarIsOpen] = useState<boolean>(sidebarIsPermanent);
  const sidebarOpen = () => setSidebarIsOpen(true);
  const sidebarClose = () => setSidebarIsOpen(false);

  // Config
  const [dashboardConfig, setDashboardConfig] = useState<DashboardConfig | null>(null);

  useEffect(() => {
    api
      .fetchDashboardConfig()
      .then((dashboard) => dashboard.kind === 'SUCCESS' && setDashboardConfig(dashboard.data));
  }, []);

  // Loading page
  if (!user || !dashboardConfig) return <Throbber full />;

  // Maintenance mode enabled
  if (dashboardConfig.isMaintenance) return <StatusMaintenance />;

  return (
    <AppContext.Provider
      value={{
        cognito: user,
        config: dashboardConfig,
        sidebar: {
          isOpen: sidebarIsOpen,
          isPermanent: sidebarIsPermanent,
          openHandler: sidebarOpen,
          closeHandler: sidebarClose
        }
      }}
    >
      <TermsOfService>{children}</TermsOfService>
    </AppContext.Provider>
  );
};

export const useApp = (): IAppContext => useContext(AppContext);

export default AppProvider;
